import React from 'react';
import { navigate } from 'gatsby-link';
import classNames from 'classnames';
import Image from '../Image';
import Video from '../Video';
import Modal from '../Modal';
import { Container } from '../Grid';
import './styles.scss';
import PropTypes, { func } from 'prop-types';
import { MEDIA_TYPE } from '../../utils/constants';
const Hero = ({
  guidSrc,
  azureSrc,
  posterSrc,
  mediaSource,
  imageSource,
  mediaType,
  breadcrumbs,
  className,
  children,
  color,
  playtext,
  modal,
  mobileSrc,
}) => {
  const isVideo = mediaType && Object.values(MEDIA_TYPE).includes(mediaType);
  const isMultiImage = Array.isArray(mediaSource);

  const handleNavigate = (to) => {
    navigate(to);
  };

  const renderAsModal = () => (
    <Modal
      video
      trigger={
        <div className="hero--img-video__modal-trigger">
          <Image
            filename="icon-video.svg"
            className="hero--img-video__modal-play-button"
          />
          <Image
            filename={posterSrc}
            className="hero--img-video__modal-image"
          />
        </div>
      }
    >
      <Video
        className="hero-img-video__video"
        src={azureSrc}
        guidSrc={guidSrc}
        poster={posterSrc}
        autoPlay={true}
      />
    </Modal>
  );

  if (isVideo && !imageSource) {
    return (
      <div className={classNames('hero', 'hero--video', className)}>
        <video autoPlay loop muted className="hero__video">
          <source src={mediaSource} type={mediaType}></source>
        </video>
        <Image className={'hero__static-mobile'} filename={mobileSrc} />
        <div className="hero__copy">{children}</div>
      </div>
    );
  } else if (isMultiImage) {
    return (
      <div className={classNames('hero', 'hero--multi', className)}>
        <div className={`hero__container-1`}>
          <Image
            className={`hero__multi-image`}
            filename={mediaSource[0].image}
          />
          <div className="hero__copy">
            <h1>{mediaSource[0].title}</h1>
            <h3>{mediaSource[0].subtext}</h3>
            <p>{mediaSource[0].text}</p>
          </div>
        </div>
        <div className={`hero__container-2`}>
          <Image
            className={`hero__multi-image`}
            filename={mediaSource[1].image}
          />
          <Image
            className={`hero__multi-image`}
            filename={mediaSource[2].image}
          />
        </div>
        {color && <div className={`hero__border hero__border--${color}`}></div>}
      </div>
    );
  } else if (imageSource) {
    return (
      <div className={classNames('hero', 'hero--img-video', className)}>
        <div className={`hero__container-1`}>
          <Image className={`hero__image`} filename={imageSource} />
          {breadcrumbs && (
            <div className="hero__breadcrumbs-container">
              <Container fullWidth>
                <div className="hero__breadcrumbs">
                  <p>
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={(e) => handleNavigate(breadcrumbs.to)}
                    >
                      {breadcrumbs.linkLabel} ›{' '}
                    </span>
                    {breadcrumbs.unitLabel}
                  </p>
                </div>
              </Container>
            </div>
          )}
          <div className="hero__copy">{children}</div>
        </div>
        <div className={'hero__container-2'}>
          {modal ? (
            renderAsModal()
          ) : (
            <Image
              filename={posterSrc}
              className="hero--img-video__poster-gif"
            ></Image>
          )}
          <div className="hero--img-video__modal-play-text">
            PRESS PLAY TO WATCH {playtext}
          </div>
        </div>
        {color && <div className={`hero__border hero__border--${color}`}></div>}
      </div>
    );
  } else {
    return (
      <div className={classNames('hero', 'hero--image', className)}>
        <Image filename={mediaSource} className="hero__img" />
        {breadcrumbs && (
          <div className="hero__breadcrumbs-container">
            <Container fullWidth>
              <div className="hero__breadcrumbs">
                <p>
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={(e) => handleNavigate(breadcrumbs.to)}
                  >
                    {breadcrumbs.linkLabel} ›{' '}
                  </span>
                  {breadcrumbs.unitLabel}
                </p>
              </div>
            </Container>
          </div>
        )}
        <div className="hero__copy">{children}</div>
        {color && <div className={`hero__border hero__border--${color}`}></div>}
      </div>
    );
  }
};

Hero.defaultProps = {
  textOverlay: null,
  className: null,
};

Hero.propTypes = {
  /** Optional prop to link to a video src from Azure. */
  azureSrc: PropTypes.string,
  /** Optional prop to link to a video src from DEX. */
  guidSrc: PropTypes.string,
  /** Optional prop to to be used with Azure video sources to display a thumbnail/video poster. */
  posterSrc: PropTypes.string,
  /** A path to video or a filename for an image (using the Image Component)
   * or an array of objects for multi image slider
   */
  mediaSource: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        subtext: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  ]),
  imageSource: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        subtext: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  ]),
  /** Optional prop to add linkable breadcrumbs.
   *  unitLabel: string
   *  linkLabel: string (Generally the course name.)
   *  to:        string (Where to link the breadcrumbs to.)
   */
  breadcrumbs: PropTypes.shape({
    unitLabel: PropTypes.string,
    linkLabel: PropTypes.string,
    to: PropTypes.string,
  }),
  /** The type used for the video */
  mediaType: PropTypes.oneOf(Object.values(MEDIA_TYPE)),
  /** Text overlay for the image/video. The hero-text-overlay-container-defaults mixin
   * contains the default CSS for a left side text overlay.
   */
  textOverlay: PropTypes.object,
  /** A custom class name */
  classNames: PropTypes.string,
  /** Optional prop to add mobile specific image mobileSrc: PropTypes.string, */
};

export default Hero;
