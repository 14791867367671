import React from 'react';
import { graphql } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import SubfooterNav from '../../../components/SubfooterNav';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/bg_living_earth_texture.jpg';
import Icon from '../../../components/Icon';
import '../../../styles/pages/_unit-sections.scss';

const LivingEarthUnit2 = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'UNIT 2',
    linkLabel: 'LIVING EARTH COURSE',
    to: '/classroom-instruction/living-earth',
  };

  const {
    // leCellEnergyInteractive, // removing interactive until V2
    leCommunityEmpowermentIntro,
    leCommunityEmpowermentJournal,
    leCommunityEmpowerment,
    leDiseaseImpact,
    leDiseaseTransmission,
    leInfectiousDiseases,
    lePersistenceOfDisease,
    leTreatmentPrevention,
  } = getKeyedResources(data);

  return (
    <Layout title="Living Earth Unit 2">
      <Hero
        className={'hero--small course__hero'}
        modal
        guidSrc="a95f93e9-a98c-43b6-9ff6-8ca4f256fd69"
        imageSource={'img-living-earth-unit2-lead.jpg'}
        posterSrc={'unit-2-living-earth-intro-video-still.gif'}
        breadcrumbs={heroBreadcrumbs}
        color={'purple'}
        playtext={'Video Intro'}
      >
        <h1 className=" mb-0 pb-0">
          Community Empowerment: Eradicating Diseases
        </h1>
        <p className="pb-2">
          How can we effectively use a community outreach campaign to educate
          and empower a community to help eradicate an infectious disease?{' '}
        </p>
      </Hero>
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            LIVING EARTH | UNIT 2
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0">STORYLINE</h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  Take on the role of a team that is tasked with creating a
                  campaign to empower a community about infectious disease and
                  its spread.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0">PACING</h3>
              <ul className="course__list">
                <li>6 week Unit</li>
                <li>45 minute classes</li>
                <li>Built in flex days</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0">FRAMING</h3>
              <ul className="course__list">
                <li>Standards Aligned</li>
                <li>Driving Question</li>
                <li>Engineering Design Process</li>
                <li>Project-based</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-lesson-plans-labs.svg" />
              <h3 className="text-bold pt-0 mt-0">LESSON PLANS AND LABS</h3>
              <ul className="course__list">
                <li>1 Project Based Introduction </li>
                <li>6 Core Lessons & Labs</li>
                <li>6 Class Presentations </li>
                <li>1 Student Project-based solution </li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">
                Lesson Plans, Labs and Student Capture Sheets
              </h3>
              <p>
                This unit includes lesson plans, labs, student capture sheets
                and presentations that empower educators to engage all students.
                These lesson bundles can be downloaded, for the use in the
                classroom.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-LE-U2.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 189.4 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        <Container fullWidth>
          <Row>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leCommunityEmpowermentIntro.tophat}
                tophatClass={`${leCommunityEmpowermentIntro.page}__resource-tophat`}
                ribbon={leCommunityEmpowermentIntro.ribbon}
                ribbonClass={`${leCommunityEmpowermentIntro.page}__resource-ribbon`}
                img=""
                duration={leCommunityEmpowermentIntro.duration}
                lab={leCommunityEmpowermentIntro.lab}
                subject={leCommunityEmpowermentIntro.subject}
                subjectClass={`${leCommunityEmpowermentIntro.page}__resource-subject`}
                title={leCommunityEmpowermentIntro.title}
                description={leCommunityEmpowermentIntro.description}
                actions={leCommunityEmpowermentIntro.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leInfectiousDiseases.tophat}
                tophatClass={`${leInfectiousDiseases.page}__resource-tophat`}
                ribbon={leInfectiousDiseases.ribbon}
                ribbonClass={`${leInfectiousDiseases.page}__resource-ribbon`}
                img=""
                duration={leInfectiousDiseases.duration}
                subject={leInfectiousDiseases.subject}
                subjectClass={`${leInfectiousDiseases.page}__resource-subject`}
                title={leInfectiousDiseases.title}
                description={leInfectiousDiseases.description}
                actions={leInfectiousDiseases.actions}
              />
            </Column>
            {/* Removing interactive until V2 */}
            {/* <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leCellEnergyInteractive.tophat}
                tophatClass={`${leCellEnergyInteractive.page}__resource-tophat`}
                ribbon={leCellEnergyInteractive.ribbon}
                ribbonClass={`${leCellEnergyInteractive.page}__resource-ribbon`}
                img=""
                duration={leCellEnergyInteractive.duration}
                lab={leCellEnergyInteractive.lab}
                subject={leCellEnergyInteractive.subject}
                subjectClass={`${leCellEnergyInteractive.page}__resource-subject`}
                title={leCellEnergyInteractive.title}
                description={leCellEnergyInteractive.description}
                actions={leCellEnergyInteractive.actions}
              />
            </Column> */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leDiseaseTransmission.tophat}
                tophatClass={`${leDiseaseTransmission.page}__resource-tophat`}
                ribbon={leDiseaseTransmission.ribbon}
                ribbonClass={`${leDiseaseTransmission.page}__resource-ribbon`}
                img=""
                duration={leDiseaseTransmission.duration}
                subject={leDiseaseTransmission.subject}
                subjectClass={`${leDiseaseTransmission.page}__resource-subject`}
                title={leDiseaseTransmission.title}
                description={leDiseaseTransmission.description}
                actions={leDiseaseTransmission.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leDiseaseImpact.tophat}
                tophatClass={`${leDiseaseImpact.page}__resource-tophat`}
                ribbon={leDiseaseImpact.ribbon}
                ribbonClass={`${leDiseaseImpact.page}__resource-ribbon`}
                img=""
                duration={leDiseaseImpact.duration}
                lab={leDiseaseImpact.lab}
                subject={leDiseaseImpact.subject}
                subjectClass={`${leDiseaseImpact.page}__resource-subject`}
                title={leDiseaseImpact.title}
                description={leDiseaseImpact.description}
                actions={leDiseaseImpact.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={lePersistenceOfDisease.tophat}
                tophatClass={`${lePersistenceOfDisease.page}__resource-tophat`}
                ribbon={lePersistenceOfDisease.ribbon}
                ribbonClass={`${lePersistenceOfDisease.page}__resource-ribbon`}
                img=""
                duration={lePersistenceOfDisease.duration}
                subject={lePersistenceOfDisease.subject}
                subjectClass={`${lePersistenceOfDisease.page}__resource-subject`}
                title={lePersistenceOfDisease.title}
                description={lePersistenceOfDisease.description}
                actions={lePersistenceOfDisease.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leTreatmentPrevention.tophat}
                tophatClass={`${leTreatmentPrevention.page}__resource-tophat`}
                ribbon={leTreatmentPrevention.ribbon}
                ribbonClass={`${leTreatmentPrevention.page}__resource-ribbon`}
                img=""
                duration={leTreatmentPrevention.duration}
                subject={leTreatmentPrevention.subject}
                subjectClass={`${leTreatmentPrevention.page}__resource-subject`}
                title={leTreatmentPrevention.title}
                description={leTreatmentPrevention.description}
                actions={leTreatmentPrevention.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leCommunityEmpowerment.tophat}
                tophatClass={`${leCommunityEmpowerment.page}__resource-tophat`}
                ribbon={leCommunityEmpowerment.ribbon}
                ribbonClass={`${leCommunityEmpowerment.page}__resource-ribbon`}
                img=""
                duration={leCommunityEmpowerment.duration}
                lab={leCommunityEmpowerment.lab}
                subject={leCommunityEmpowerment.subject}
                subjectClass={`${leCommunityEmpowerment.page}__resource-subject`}
                title={leCommunityEmpowerment.title}
                description={leCommunityEmpowerment.description}
                actions={leCommunityEmpowerment.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={leCommunityEmpowermentJournal.tophat}
                tophatClass={`${leCommunityEmpowermentJournal.page}__resource-tophat`}
                ribbon={leCommunityEmpowermentJournal.ribbon}
                ribbonClass={`${leCommunityEmpowermentJournal.page}__resource-ribbon`}
                img=""
                duration={leCommunityEmpowermentJournal.duration}
                subject={leCommunityEmpowermentJournal.subject}
                subjectClass={`${leCommunityEmpowermentJournal.page}__resource-subject`}
                title={leCommunityEmpowermentJournal.title}
                description={leCommunityEmpowermentJournal.description}
                actions={leCommunityEmpowermentJournal.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Unit 2',
            link: '/classroom-instruction/living-earth/unit-2',
          }}
          previous={{
            name: 'Unit 1',
            link: '/classroom-instruction/living-earth/unit-1',
          }}
        />
      </Section>
    </Layout>
  );
};

export default LivingEarthUnit2;

export const query = graphql`
  query LivingEarthUnit2Resources {
    allResourcesJson(
      filter: { page: { eq: "living-earth" }, unit: { eq: 2 } }
    ) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
