import Modal from '../Modal';
import Video from '../Video';
import Subscribe from '../Subscribe';
import Button from '../Button';
import Icon from '../Icon';
import { Link } from 'gatsby';
import OutboundLink from '../OutboundLink';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.scss';
import { urlHasExtension } from '../../utils/resource-helper';

const Action = ({
  className,
  label,
  type,
  format,
  size,
  path,
  url,
  youtube_id,
  vimeo_id,
  azure_src,
  posterSrc,
  guidSrc,
  autoplay,
  locked,
  title,
  actions,
  lightMetadata,
  moduleName,
  clickType,
  clickLabel,
  videoButtonClassOverride,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const handleDropdownClick = () => setDropdownOpen((state) => !state);

  return (
    <div
      className={classNames(
        'resource__action',
        locked && 'resource__action--locked',
        type === 'dropdown' && 'resource__action--with-dropdown',
        className
      )}
    >
      {locked ? (
        <div className="resource__action-placeholder">
          <Icon name="lock" />
          {label}
        </div>
      ) : (
        <>
          {type === 'external' && (
            <OutboundLink to={url} withIcon className="resourceButton">
              {label}
            </OutboundLink>
          )}
          {type === 'video' && (
            <Modal
              trigger={
                <button
                  className={classNames(
                    'dropdown-video',
                    videoButtonClassOverride
                  )}
                >
                  <Icon name="play" marginRight />
                  {label}
                </button>
              }
              video
            >
              <Video
                guidSrc={guidSrc}
                posterSrc={posterSrc}
                autoplay={autoplay}
                title={title}
              />
            </Modal>
          )}
          {type === 'subscribe' && (
            <Subscribe
              trigger={
                <Button
                  className="button--no-border resource__action-link"
                  link={true}
                >
                  {label}
                </Button>
              }
            />
          )}
          {type === 'download' && (
            <a
              href={path}
              target="_blank"
              // If the URL has an extension, we don't want to show the data-click-type and data-click-name attributes.
              data-click-type={
                urlHasExtension(path)
                  ? null
                  : clickType
                  ? clickType
                  : moduleName
                  ? 'Module Launch'
                  : null
              }
              data-click-name={
                urlHasExtension(path) ? null : clickLabel || moduleName || null
              }
            >
              <Icon name="download" />
              {label}
            </a>
          )}
          {type === 'module' && (
            <a
              href={path}
              target="_blank"
              data-click-type={
                urlHasExtension(path)
                  ? null
                  : clickType
                  ? clickType
                  : moduleName
                  ? 'Module Launch'
                  : null
              }
              data-click-name={
                urlHasExtension(path) ? null : moduleName || clickLabel || null
              }
            >
              <Icon name="module" />
              {label}
            </a>
          )}
          {type === 'dropdown' && (
            <button
              onClick={handleDropdownClick}
              className="resource__dropdown-toggle"
            >
              <Icon
                name="caratdown"
                className={classNames(
                  'resource__dropdown-carat',
                  dropdownOpen && 'resource__dropdown-carat--open'
                )}
              />
              {label}
            </button>
          )}
          {type === 'link' && (
            <Link to={path} className="resource__action-link">
              {label}
            </Link>
          )}
        </>
      )}
      {format && (
        <span
          className={classNames(
            'resource__format',
            lightMetadata && 'color-white'
          )}
        >
          {format}
        </span>
      )}
      {size && (
        <span
          className={classNames(
            'resource__size',
            lightMetadata && 'color-white'
          )}
        >
          {size}
        </span>
      )}
      {type === 'dropdown' && dropdownOpen && (
        <div className="resource__dropdown-items">
          {actions.map((action, i) => (
            <Action {...action} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

Action.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  format: PropTypes.string,
  size: PropTypes.string,
  path: PropTypes.string,
  url: PropTypes.string,
  guidSrc: PropTypes.string,
  posterSrc: PropTypes.string,
  youtube_id: PropTypes.string,
  vimeo_id: PropTypes.string,
  locked: PropTypes.bool,
  title: PropTypes.string,
  clickType: PropTypes.string,
  clickLabel: PropTypes.string,
  moduleName: PropTypes.string,
  linkFromImg: PropTypes.bool,
};

export default Action;
