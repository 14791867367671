import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import Icon from '../Icon';
import Image from '../Image';

import './styles.scss';

const SubfooterNav = ({ current, next, previous }) => {
  const handleChange = (selectedOption) => {
    navigate(selectedOption);
  };

  return (
    <div className="subfooter-nav__container">
      <Image
        filename="img-bg-subfooter-nav.jpg"
        className="subfooter-nav__bg-image"
      />
      {previous && (
        <Link
          to={previous.link}
          className="subfooter-nav__link subfooter-nav__link-previous pl-2"
        >
          <Icon name="arrowleft" marginRight className="subfooter-nav__arrow" />
          {previous.name}
        </Link>
      )}
      <div className="subfooter-nav__unit-selector-container">
        <label htmlFor="units">SELECT A UNIT TO CONTINUE</label>
        <select
          name="units"
          id="unit-select"
          onChange={(e) => handleChange(e.currentTarget.value)}
        >
          <option value={current.link}>{current.name}</option>
          {next ? (
            <option value={next.link}>{next.name}</option>
          ) : (
            <option value={previous.link}>{previous.name}</option>
          )}
        </select>
      </div>
      {next && (
        <Link
          to={next.link}
          className="subfooter-nav__link subfooter-nav__link-next pr-2"
        >
          {next.name}
          <Icon name="arrowright" marginLeft className="subfooter-nav__arrow" />
        </Link>
      )}
    </div>
  );
};

SubfooterNav.propTypes = {
  /** The name and internal link location of the current page */
  current: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
  /** The name and internal link location of the next page */
  next: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
  /** The name and internal link location of the previous page */
  previous: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
};

export default SubfooterNav;
