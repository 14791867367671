const deslugify = (slug) => {
  let splitSlug = slug.split('-');
  let camelCased = '';

  for (let i = 1; i < splitSlug.length; i++) {
    splitSlug[i] = splitSlug[i].charAt(0).toUpperCase() + splitSlug[i].slice(1);
  }

  camelCased = splitSlug.join('');
  return camelCased;
};

/**
 * Extracts the resources from the data object and flattens it to an
 * array of resources (objects).
 *
 * @param {Object} data GraphQL query response
 *
 * @returns {Array} An array of resources (objects)
 */
export const getRawResources = (data) =>
  data.allResourcesJson.edges.reduce((acc, curr) => {
    acc.push(curr.node);
    return acc;
  }, []);

/**
 * Extracts the resources from the data object and modifies it into
 * an object with keys generated from the resource slug value. So a
 * resource with a slug value of "slug-name" will have a key of
 * "slugName". This is best used to destructure the resources you
 * want from the query.
 *
 * @param {Object} data GraphQL query response
 *
 * @returns {Object} An object with camel case keys generated from
 * the slug property of a resource.
 */
export const getKeyedResources = (data) =>
  getRawResources(data).reduce((acc, curr) => {
    acc[deslugify(curr.slug)] = curr;
    return acc;
  }, {});

/**
 * Checks whether the given url has an extension.
 * @param {String} url The url to check
 * @returns {Boolean} True if the url has an extension, false otherwise
 */
export const urlHasExtension = (url) => {
  var fileExtensionRegex = new RegExp(
    /(7z|csv|doc(x|m)?|mp(2|3|4|e?g)|pdf|ppt(x|m)?|pps(x|m)?|tar|tgz|wav|xls(x|m|b)?|xlt(x|m)|zip)$/i
  )
  if (fileExtensionRegex.test(url.split(".").pop())) {
    return true;
  } else {
    return false;
  }
}
